import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link, useParams } from "react-router-dom";
import AdminSideMenu from "../AdminSideMenu";
import { Admin } from '../../../Services/Admin';
import { NotificationManager } from 'react-notifications';
import config from "./../../../config";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FecthData } from '../../../Services/FetchData';
import { School } from '../../../Services/School';
import { getResourceUrl } from '../../../Services/CommanFunctions';
import NotificationUI from '../../common/NotificationUI';

function Admins() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [school, setSchool] = useState({});
  const [tutor, setTutor] = useState();
  const [index, setIndex] = useState(0)
  const [selectedAdmin, selectAdmin] = useState();
  const [error, setError] = useState();
  const [fieldError, setFieldError] = useState();
  const [validated, setValidated] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'))
  const { id } = useParams();
  const history = useHistory()
  const [schlFormData, setSchlFormData] = useState({
    school: "",
    school_id: id,
    school_name: "",
    school_code: "",
    school_street: "",
    school_city: "",
    school_state: "",
    school_zip: "",
    logo: "",
    logo_url: "",
    display_logo: "yes",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    contact_by: [],
    accept_privacy_policy: false
  })

  const setSchoolInfo = (item) => {
    setSchlFormData({
      ...schlFormData,
      school: item,
      school_id: item._id,
      school_name: item.school_name,
      school_code: item.school_code,
      school_street: item.street,
      school_city: item.city,
      school_state: item.state,
      school_zip: item.zip_code
    })
  }
  const handleContactBy = e => {
    const { value, checked } = e.target;
    const { contact_by } = schlFormData;
    if (checked) {
      setSchlFormData(prevState => ({
        ...prevState,
        contact_by: [...contact_by, value],
      }));

    } else {
      setSchlFormData(prevState => ({
        ...prevState,
        contact_by: contact_by.filter((e) => e !== value),
      }));

    }
  }
  const submitData = (e) => {
    e.preventDefault()
    let formData = new FormData();
    Object.entries(schlFormData).map(([key, value]) => {
      if (key !== 'contact_by') {
        formData.append(key, value);
      } else {
        formData.append(key, value.toString());
      }

    })
    School.registerSchool(formData).then(
      (data) => {
        if (!data.errors) {
          // console.log(message)
          NotificationManager.success(data.message, 'success!', 2000);
          handleClose()
          // setMessage(data.message)
          // history.push('/school');
        } else {
          NotificationManager.error(data.message.toString(), 'error!', 2000);
        }
      },

      (error) => {

        if (error.error == 1) {

        }
        NotificationManager.error(error.toString(), 'error!', 2000);
        setError(error)
      }
    )
  }
  const getTutorsOfSchool = () => {
    FecthData.getData('users/get_admin_users_list').then(
      (data) => {
        setTutor(data?.data)
        if (index > -1)
          selectAdmin(data?.data[index])
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
    // Admin.post({ 'page': 0, 'school_id': id }, 'tutor/get_school_wise_tutor').then(
    //   (data) => {
    //     setTutor(data?.data)
    //     if (index)
    //       selectTutor(data?.data[index])
    //   },
    //   (error) => {
    //     setError(error.toString())
    //     NotificationManager.error(error.toString(), 'error!', 2000);
    //   }
    // )
  }
  useEffect(() => {
    getTutorsOfSchool()
    // Admin.post({ '_id': id }, 'school/get_school_by_id').then(
    //   (data) => setSchool(data),
    //   (error) => {
    //     setError(error.toString())
    //     NotificationManager.error(error.toString(), 'error!', 2000);
    //   }
    // )
  }, []);
  // const submitForm = () => {
  //   FecthData.postJsonData({ ...formData, school_id: id }, 'tutor/add_tutor').then(
  //     (data) => {
  //       if (data?.data) {
  //         NotificationManager.success(data.message, 'success!', 2000)
  //         
  //         handleClose()
  //       } else {
  //         setFieldError(data.errors.details)
  //         
  //       }
  //     }
  //   )
  // }
  const changeUserStatus = (userId, changedStatus) => {
    Admin.changeUserStatus({ user_id: userId, status: changedStatus }).then(res => {
      getTutorsOfSchool()
    })
  }
  const logout = () => {
    localStorage.clear()
    history.push('/admin')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={6} className="mt-2">
                  <div className="d-flex align-items-center">
                    <div className="school-logo-small">
                      <img src="images/admin-avatar.png" alt="" />
                    </div>
                    <h4 className="text-muted mb-0">{school?.data?.school_name}</h4>
                  </div>
                </Col>
                {/* <Col md={6} className="mt-4">
                  <div className="filters">
                    <ul>
                      <li>
                        <a href="javascript:void(0)">Sort <img src="images/sort-icon.png" alt="" width="17" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">Filter <img src="images/filter-icon.png" alt="" width="13" />
                        </a>
                      </li>
                      <li onClick={handleShow}>
                        <a href="javascript:void(0)">Add <img src="images/add-icon.png" alt="" width="15" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col> */}
                <Col md={12}>
                  <div className="video-category">
                    <ul>
                      <li>
                        <Link to={'#' + id}>Dasya Admins</Link>
                      </li>
                      {/* <li >
                        <Link to={'/admin/school/tutor/' + id}>Tutors</Link>
                      </li>
                      <li>
                        <Link to={'/admin/school/facilitator/' + id}>Facilitators</Link>
                      </li>
                      <li>
                        <Link to={'/admin/school/parent/' + id}>Parents/Guardians</Link>
                      </li>
                      <li className="active">
                        <Link
                          to={'/admin/school/admin/' + id}
                        >School Admin</Link>
                      </li> */}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">ID</th>
                          {/* <th scope="col">Role</th> */}
                          {/* <th scope="col">Gender</th> */}
                          <th scope="col">Email</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tutor?.map((content, index) => (
                          <tr onClick={() => {
                            setIndex(index)
                            selectAdmin(content)
                          }} style={{ cursor: 'pointer' }} >
                            <td>
                              {content?.user_id && content?.user_id?.display_logo == 'yes' && content?.user_id?.propic && <img src={getResourceUrl(content?.user_id?.propic)} alt="" width="44" className="rounded me-2" />}
                              {content?.user_id?.name}
                            </td>
                            <td>{content?._id}</td>
                            {/* <td>{content?.role_data[0]?.role}</td> */}
                            {/* <td>Female</td> */}
                            <td>{content?.email}</td>
                            {/* <td><a className="btn btn-primary btn-sm" onClick={() => history.push({ pathname: "/admin/user/edit", state: { ...content, ...content?.user_id, user_role: 'tutor', _id: content?._id } })}>Edit</a></td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>

          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.status} | {user?.role}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {selectedAdmin ? <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src={selectedAdmin?.user_id?.propic ? getResourceUrl(selectedAdmin?.user_id?.propic) : "images/teacher-avatar.jpg"} alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {/* <Dropdown.Item><Link to={{ pathname: "/admin-tutor-profile", state: selectedAdmin }}>View Profile</Link></Dropdown.Item>
                      <Dropdown.Divider /> */}
                      <Dropdown.Item><Link className={'dropdown-item'} to={{ pathname: "/admin/user/edit", state: { ...selectedAdmin, user_role: 'admin', status: selectedAdmin?.user_id?.status, name: selectedAdmin?.user_id.name, user_sf_id: selectedAdmin?.user_id?._id,propic:selectedAdmin?.user_id?.propic } }}>Edit Profile</Link></Dropdown.Item>
                      {/* <Dropdown.Divider />
                      <Dropdown.Item href="javascript:void(0)">Delete Profile</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{selectedAdmin?.first_name} {selectedAdmin?.last_name}</h5>
                  <h6>ID: 567825</h6>
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li className='phone-li'>
                      <a href={`tel:${selectedAdmin?.phone}`} itemRef='ghgf'>
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                      {selectedAdmin?.phone ? <span className='show-phone'>{selectedAdmin?.phone}</span> : <span className='show-phone'>Phone Not Available</span>}
                    </li>
                    <li>
                      <a href={`mailto:${selectedAdmin?.email}`}>
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="javascript:void(0)" className={`btn btn-${selectedAdmin?.user_id?.status == 'Active' ? 'primary' : 'secondary'} solid btn-sm no-shadow d-block`}>Status: {selectedAdmin?.user_id?.status}</a>
                </div>
                <div className="about-teacher">
                  {/* <h4>About:</h4>
                  <p>{selectedAdmin?.about}
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                  {/* <h4>Experience:</h4>
                  <p>{selectedAdmin?.tutoring_experience}
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                  {/* <h4>Gender:</h4>
                  <p>{ }</p>
                  <h4>Date of birth</h4>
                  <p>2 Jan 2003</p> */}
                  <h4>Email:</h4>
                  <p>{selectedAdmin?.email}</p>
                  <h4>Phone:</h4>
                  <p>{selectedAdmin?.phone}</p>
                  <h4>Department:</h4>
                  <p>{selectedAdmin?.department}</p>
                  {/* <h4>Subjects Interested In Teaching:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      {selectedAdmin?.subjects.map(subject => <li>{subject}</li>)}
                      <li>
                        Algebra
                      </li>
                      <li>
                        Science
                      </li>
                      <li>
                        Physics
                      </li>
                      <li>
                        Geography
                      </li>
                      <li>
                        Calculus
                      </li>
                    </ul>
                  </div> */}
                </div>
                {selectedAdmin?.user_id?.status == 'Inactive' ? <Row>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={() => changeUserStatus(selectedAdmin?.user_id, 'Active')}>Admit</Button>
                  </Col>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill" variant="secondary" onClick={() => changeUserStatus(selectedAdmin?.user_id, 'Inactive')}>Decline</Button>
                  </Col>
                </Row> : null}
              </div>
            </div> : null}
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add School Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitData}>
            <div className="modal-form">
              <Row>
                {/* <Form.Group className="form-group mb-3" as={Col} md="12">
                <Form.Control type="text" placeholder="Search by school name, city or zip code" />
              </Form.Group> */}
                {/* <Form.Group className="form-group mb-3" style={{ zIndex: 2 }}>
                  {/* <Form.Control type="text" placeholder="Search" /> 
                  <Autocomplete className="search"
                    items={school || []}
                    shouldItemRender={(item, value
                    ) => item.school_name.toLowerCase()
                      .indexOf(value.toLowerCase()) > -1}
                    getItemValue={item => item.school_name}
                    renderItem={(item, isHighlighted) =>
                      <div style={{
                        background: isHighlighted ?
                          '#eee' : 'white',
                        padding: '5px 10px',
                      }}
                        key={item.id}>
                        {item.school_name}
                      </div>
                    }
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    onSelect={(val, item) => {
                      setValue(val)
                      setSchoolInfo(item)
                    }}
                    inputProps={{
                      style: {
                        width: '100%',
                        background: '#E9ECF4',
                        // boxShadow: '0 0 8px rgba(0,0,0,0.25)',
                        width: '100%',
                        color: '#000',
                        borderRadius: 10,
                        minHeight: 52,
                        border: '1px solid #E9ECF4',
                        fontSize: 14,
                        paddingLeft: 15,
                        paddingRight: 15,
                      },
                      placeholder: 'Search by school name, city or zip code'
                    }}
                    wrapperStyle={{
                      width: '100%',
                    }}
                  />
                  <Button variant="primary" type="submit" className="search-btn" style={{ right: 18 }}>
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group> */}
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Point of Contact First Name*" value={schlFormData.first_name} onChange={(e) => setSchlFormData({ ...schlFormData, first_name: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Point of Contact Last Name*" value={schlFormData.last_name} onChange={(e) => setSchlFormData({ ...schlFormData, last_name: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Contact number*" value={schlFormData.phone} onChange={(e) => setSchlFormData({ ...schlFormData, phone: e.target.value })} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" placeholder="Email*" value={schlFormData.email} onChange={(e) => setSchlFormData({ ...schlFormData, email: e.target.value })} />
                </Form.Group>
                {/* <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="School Name" value={schlFormData.school_name} />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.school_code} placeholder="School Code" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.school_street} placeholder="Address" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.school_city} placeholder="City" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.school_state} placeholder="State" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value={schlFormData.school_zip} placeholder="Zip Code" />
                </Form.Group> */}
                <Col md={12} className='m-2'>
                  <Form.Group className="form-group iagree mb-3" controlId="formbymail">
                    <Form.Check className="text-grey" name="contact_by" type="checkbox" onChange={(e) => handleContactBy(e)} checked={schlFormData.contact_by.includes('email')} label="Contact Me By Email" value="email" />
                  </Form.Group>
                  <Form.Group className="form-group iagree mb-3" controlId="formbyphone">
                    <Form.Check className="text-grey" name="contact_by" type="checkbox" onChange={(e) => handleContactBy(e)} checked={schlFormData.contact_by.includes('phone')} label="Contact Me By Phone" value="phone" />
                  </Form.Group>
                </Col>
                <Col md="12" className="text-center mt-2">
                  {/* <ActionButton title={'Send Request'} type={'submit'} requiredFields={[schlFormData.school_id, schlFormData.first_name, schlFormData.last_name, schlFormData.phone, schlFormData.email]} /> */}
                  {/* <Button className="px-4 btn-pill" variant="primary" onClick={submitData}>Send Request</Button> */}
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default Admins
