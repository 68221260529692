import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import SchoolSideMenu from "./SchoolSideMenu";
import { NotificationManager } from 'react-notifications';
import { School } from '../../Services/School';
import config from '../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Admin } from '../../Services/Admin';
import { FecthData } from '../../Services/FetchData';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';
import { getMaskedText } from '../../helpers/utils';

function SchoolFacilitator() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [isVisible, setIsVisible] = useState(true);
  const [facilitator, setFacilitator] = useState({});
  const [selectedFacilitator, selectFacilitator] = useState()
  const [error, setError] = useState();
  const [value, setValue] = useState('');
  const [validated, setValidated] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'))
  const [filter, setFilter] = useState('')

  const getSchoolFacilitator = () => {
    FecthData.getData('facilitator/school_wise_facilitator_list/' + user?.school?.school_id + '?page=' + 0 + '&filter=' + filter).then(
      (data) => {
        setFacilitator(data)
        // selectFacilitator(data?.data[0])
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), '!', 2000);
      }
    )
  }
  useEffect(() => {
    getSchoolFacilitator()
  }, [filter]);
  const changeUserStatus = (userId, changedStatus) => {
    Admin.changeUserStatus({ user_id: userId, status: changedStatus }).then(res => {
      getSchoolFacilitator()
    })
  }
  let history = useHistory()
  const logout = () => {
    localStorage.clear()
    history.push('/school')
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <SchoolSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={6} className="mt-4">
                  <h3 className="text-muted mb-0">Dashboard</h3>
                </Col>
                <Col md={6} className="mt-4">
                  <div className="filters">
                    <ul>
                      <li>
                        {/* <a href="javascript:void(0)">Sort <img src="images/sort-icon.png" alt="" width="17" />
                        </a> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Sort</option>
                          <option value={'ASC'}>Alphabetic A-Z</option>
                          <option value={'DESC'}>Alphabetic Z-A</option>
                        </Form.Select>
                      </li>
                      <li>
                        {/* <a href="javascript:void(0)">Filter <img src="images/filter-icon.png" alt="" width="13" />
                        </a> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Filter</option>
                          <option value={'Last Modified'}>Last Modified</option>
                          <option value={'Oldest'}>Oldest</option>
                          <option value={'Newest'}>Newest</option>
                        </Form.Select>
                      </li>
                      {/* <li onClick={handleShow}>
                        <a href="javascript:void(0)" >Add <img src="images/add-icon.png" alt="" width="15" />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="video-category" style={{ paddingLeft: 0, }}>
                    <ul style={{ justifyContent: 'flex-start' }}>
                      <li>
                        <Link to="/school-dashboard">Students</Link>
                      </li>
                      <li>
                        <Link to="/school-tutor">Tutors</Link>
                      </li>
                      <li className="active">
                        <Link to="/school-facilitator">Facilitators</Link>
                      </li>
                      <li >
                        <Link to="/school-parent">Parents</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">ID</th>
                          <th scope="col">Role</th>
                          {/* <th scope="col">Gender</th> */}
                          <th scope="col">Email</th>
                          {/* <th scope="col">Edit</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {/* {(tutor && tutor?.data?.map((content, index) => (
                          <tr key={index}>
                            <td>
                              {(content?.display_logo == 'yes' && content?.user_details[0] && content?.user_details[0]?.propic != '') && <img src={content?.user_details[0]?.propic} alt="" width="44" className="rounded me-2" />}
                              {content.user_details[0].name}
                            </td>
                            <td>{content.user_id}</td>
                            <td>{'Tutor'}</td>
                            <td>{content.user_details[0].email}</td>
                          </tr>
                        )))} */}
                        {(facilitator && facilitator?.data?.map((content, index) => (
                          <tr key={index} onClick={() => selectFacilitator(content)}>
                            <td>
                              {(content?.display_logo == 'yes' && content?.facilitators_details[0] && content?.facilitators_details[0]?.propic != '') ? <img src={getResourceUrl(content?.facilitators_details[0]?.propic)} alt="" width="44" className="rounded me-2" />:
                              <img src={'images/no_profile_img.png'} alt="" width="44" className="rounded me-2" />}
                              {getMaskedText(content.facilitators_details[0].name,'name') }
                            </td>
                            <td>{content._id}</td>
                            {/* <td>{content.user_role}</td> */}
                            <td>{'Facilitator'}</td>
                            <td>{getMaskedText(content.facilitators_details[0].email,'email')}</td>
                            {/* <td><a className="btn btn-primary btn-sm" onClick={() => {
                              alert("work in progress")
                              //  history.push({ pathname: "/admin/user/edit", state: { ...content?.facilitators_details[0], facilitator_id: content?._id, id: content?.facilitator_id, user_role: 'facilitator', student_id: content?.student_id, _id: content?._id } })
                            }
                            }>Edit</a></td> */}
                          </tr>
                        )))}
                        {/* <tr>
                          <td>
                            <img src="images/avatar.jpg" alt="" width="44" className="rounded me-2" />
                            Sandra D
                          </td>
                          <td>642745</td>
                          <td>Facilitator</td>
                          <td>Male</td>
                          <td>ruben@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar.jpg" alt="" width="44" className="rounded me-2" />
                            Andy Fay Ruben
                          </td>
                          <td>164782</td>
                          <td>Student</td>
                          <td>Non-Binary</td>
                          <td>sandy@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar.jpg" alt="" width="44" className="rounded me-2" />
                            Andy Fay Ruben
                          </td>
                          <td>764419</td>
                          <td>Student</td>
                          <td>Female</td>
                          <td>carla@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar.jpg" alt="" width="44" className="rounded me-2" />
                            Andy Fay Ruben
                          </td>
                          <td>642745</td>
                          <td>Teacher</td>
                          <td>Male</td>
                          <td>ruben@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar.jpg" alt="" width="44" className="rounded me-2" />
                            Jessica R. Brownlee
                          </td>
                          <td>164782</td>
                          <td>Teacher</td>
                          <td>Non-Binary</td>
                          <td>sandy@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar.jpg" alt="" width="44" className="rounded me-2" />
                            Jeffrey S. Douglas
                          </td>
                          <td>642745</td>
                          <td>Teacher</td>
                          <td>Male</td>
                          <td>ruben@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar.jpg" alt="" width="44" className="rounded me-2" />
                            Jessica R. Brownlee
                          </td>
                          <td>164782</td>
                          <td>Asst. Professor</td>
                          <td>Non-Binary</td>
                          <td>sandy@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar.jpg" alt="" width="44" className="rounded me-2" />
                            Debra A. Bragg
                          </td>
                          <td>764419</td>
                          <td>Asst. Professor</td>
                          <td>Female</td>
                          <td>carla@mather.edu</td>
                        </tr>
                        <tr>
                          <td>
                            <img src="images/avatar.jpg" alt="" width="44" className="rounded me-2" />
                            Jessica R. Brownlee
                          </td>
                          <td>164782</td>
                          <td>Asst. Professor</td>
                          <td>Non-Binary</td>
                          <td>sandy@mather.edu</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="user-short-name">
                  <h5>{user?.school?.school_name}</h5>
                  <p>Status: {user?.status}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {selectedFacilitator ? <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src={selectedFacilitator?.facilitators_details[0]?.propic?getResourceUrl(selectedFacilitator?.facilitators_details[0]?.propic):"images/no_profile_img.png"} alt="" />
                </div>
                {/* <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#">View Profile</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#">Edit Account</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#">Delete Account</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}
                <div className="school-name">
                  <h5>{getMaskedText(selectedFacilitator?.facilitators_details[0]?.name,'name')}</h5>
                  {/* <h6>ID: 567872</h6> */}
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li className='phone-li'>
                      <a href={`tel:${selectedFacilitator?.phone}`} itemRef='ghgf'>
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                      {selectedFacilitator?.facilitators_details[0]?.phone ? <span className='show-phone'>{getMaskedText(selectedFacilitator?.facilitators_details[0]?.phone,'phone')}</span> : <span className='show-phone'>Phone Not Available</span>}
                    </li>
                    <li>
                      <a href={`mailto:${selectedFacilitator?.facilitators_details[0]?.email}`}>
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="javascript:void(0)" className={`btn btn-${selectedFacilitator?.facilitators_details[0].status == 'Active' ? 'primary' : 'secondary'} solid btn-sm no-shadow d-block`}>Status: {selectedFacilitator?.facilitators_details[0].status}</a>
                </div>
                <div className="about-teacher">
                  {/* <h4>About:</h4>
                  <p>
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                  <h4>Experience:</h4>
                  <p>
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                  <h4>Gender:</h4>
                  <p>Female</p>
                  <h4>Date of birth</h4>
                  <p>2 Jan 2003</p> */}
                  <h4>Email:</h4>
                  <p>{getMaskedText(selectedFacilitator?.facilitators_details[0]?.email,'email')}</p>
                  {/* <h4>Students:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      <li>
                        Piter
                      </li>
                      <li>
                        David
                      </li>
                      <li>
                        Alex
                      </li>
                      <li>
                        Jhone
                      </li>
                    </ul>
                  </div> */}
                </div>
                {selectedFacilitator?.facilitators_details[0].status == 'Inactive' ? <Row>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill no-shadow same-width" variant="primary" onClick={() => changeUserStatus(selectedFacilitator?.facilitator_id, 'Active')}>Admit</Button>
                  </Col>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill same-width" variant="secondary" onClick={() => changeUserStatus(selectedFacilitator?.facilitator_id, 'Inactive')}>Decline</Button>
                  </Col>
                </Row> : null}
              </div>
            </div> : null}
          </div>
        </div>
      </section>

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Facilitator</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="modal-form">
              <Row>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="First Name*" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Last Name*" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Contact Number*" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="Email*" />
                </Form.Group>
                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill" variant="primary" onClick={() => alert('work in progress')}>Send Request</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default SchoolFacilitator
