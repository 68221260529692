import _ from "lodash"

export const staticData={
    years:{
        data: [
            {
                "value":"2020-2021", "label": "2020 - 2021"
            },
            {
                "value":"2021-2022", "label": "2021 - 2022"
            },
            {
                "value":"2022-2023", "label": "2022 - 2023"
            },
            {
                "value":"2023-2024", "label": "2023 - 2024"
            },
            {
                "value":"2024-2025", "label": "2024 - 2025"
            },
            {
                "value":"2025-2026", "label": "2025 - 2026"
            }
        ],
        getYears: () =>  staticData?.years?.data,
        getYearByValue: (yr) =>  _.find(staticData?.years?.data,{value:yr})
    },
    timezone:{
        EST:'-04:00',
        PST:'-07:00',
        IST:'+05:30',
        CST:'-05:00',
        getOffsetByTZ:(tz)=> staticData?.timezone[tz]
    },
    privacyText:{
        admin:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        school:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        company:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        tutor:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        student:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        parent:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        facilitator:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        getPrivacyTextByUserType:(type)=> staticData?.privacyText?.[type]
    },
    verbiage:{
        admin:{
            1: {
                heading: "Select Profile Image",
                text: "Your profile image is used as the icon for your personal space and to represent you in Dasya."
            },
            2: {
                heading: "Add Your Details",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quis lorem ut libero malesuada feugiat."
            },
            3: {
                heading: "Verify Your Account ",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae."
            },
            4: {
                heading: "Privacy Notice ",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque velit nisi, pretium ut lacinia in, elementum id enim."
            },
            5: {
                heading: "Quis autem vel eum iure",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus."
            },
            6:{
                heading: "Quis autem vel eum iure",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus."
            } 
        },
        school:{
            1: {
                heading: "Select Profile Image ",
                text: " Your profile image is used as the icon for your personal space and to represent you in Dasya"
            },
            2: {
                heading: "Add Your Details ",
                text: "The school information is uneditable. Please contact Dasya at dasyausa@gmail.com to make any updates to the school information."
            },
            3: {
                heading: "Verify Your Account ",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae."
            },
            4: {
                heading: "Privacy Notice",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque velit nisi, pretium ut lacinia in, elementum id enim."
            },
            5: {
                heading: "Quis autem vel eum iure",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus."
            },
            6:{
                heading: "Quis autem vel eum iure",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus."
            }
        },
        company:{
            1: {
                heading: "Select Profile Image ",
                text: " Your profile image is used as the icon for your personal space and to represent you in Dasya"
            },
            2: {
                heading: "Add Your Details ",
                text: " The organization information is uneditable. Please contact Dasya at dasyausa@gmail.com to make any updates to the organization information."
            },
            3: {
                heading: "Verify Your Account ",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae."
            },
            4: {
                heading: "Privacy Notice ",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque velit nisi, pretium ut lacinia in, elementum id enim."
            },
            5: {
                heading: "Quis autem vel eum iure",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus."
            },
            6:{
                heading: "Quis autem vel eum iure",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus."
            }
        },
        tutor:{
            1: {
                heading: "Let’s Get You Registered",
                text: "Start by select your school/company from the list of our registered partners."
            },
            2: {
                heading: "Select Profile Image",
                text: "Your profile image is used as the icon for your personal space and to represent you in Dasya"
            },
            3: {
                heading: "Add Your Details",
                text: "A college degree is not required for becoming a mentor. We welcome all talents to join our community as long as you are a great fit. Sharing your teaching philosophy and style will help us place you in the best student matches."
            },
            4: {
                heading: "Verify Your Account",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque velit nisi, pretium ut lacinia in, elementum id enim."
            },
            5: {
                heading: "What subjects would you like to teach?",
                text: " What are the subjects of your highest expertise? What are your favorite subjects to teach?. Approved subjects will appear in your profile. You may also opt out of any subjects you don’t want to teach anytime."
            },
            6:{
                heading: "When are you available for your first session?",
                text: "Select the days and time on which you would like to schedule a tutoring session. Based on your schedule preference we will help match you with a suitable tutor."
            }
        },
        student:{
            1: {
                heading: "Let’s Get You Registered ",
                text: "Create your Student account by selecting your school, grade and academic year. We’re so glad you’re here!"
            },
            2: {
                heading: "Choose Your Avatar! ",
                text: "Select an Avatar from our list that best suits you."
            },
            3: {
                heading: "Add your personal details!",
                text: "Add your personal details and your parent/guardian’s email address to obtain parental consent to be added to Dasya."
            },
            4: {
                heading: "Verify Your Account ",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque velit nisi, pretium ut lacinia in, elementum id enim."
            },
            5: {
                heading: " What subject would you like to learn?",
                text: "Get the assistance you need by selecting the subjects you need help with."
            },
            6:{
                heading: "When are you available for your first session?",
                text: "Select the days and time on which you would like to schedule a tutoring session. Based on your schedule preference we will help match you with a suitable tutor."
            }
        },
        parent:{
            1: {
                heading: "Verify Your Account ",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            },
            2: {
                heading: "Select Child’s Account",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quis lorem ut libero malesuada feugiat."
            },
            3: {
                heading: "Add Your Details ",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae."
            },
            4: {
                heading: "Privacy Notice ",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque velit nisi, pretium ut lacinia in, elementum id enim."
            },
            5: {
                heading: "Quis autem vel eum iure",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus."
            },
            6:{
                heading: "Quis autem vel eum iure",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus."
            }
        },
        facilitator:{
            1: {
                heading: "Verify Your Account ",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
            },
            2: {
                heading: "Select Child’s Account",
                text: "Start by select your school from the list of schools registered with us."
            },
            3: {
                heading: "Facilitator Request ",
                text: " A student has requested you as the facilitator. Accept request to become a facilitator and view student’s schedule. "
            },
            4: {
                heading: "Privacy Notice ",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque velit nisi, pretium ut lacinia in, elementum id enim."
            },
            5: {
                heading: "Quis autem vel eum iure",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus."
            },
            6:{
                heading: "Quis autem vel eum iure",
                text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus."
            }
        },
        getVerbiageText:(type,step)=> staticData?.verbiage?.[type]?.[step]
    }
}