import React, { useState, useEffect } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
  Modal,
  DropdownButton,
  Dropdown
} from "react-bootstrap";
import { NavLink, Link, useParams } from "react-router-dom";
import AdminSideMenu from "./AdminSideMenu";
import { Admin } from '../../Services/Admin';
import { NotificationManager } from 'react-notifications';
import config from "../../config";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FecthData } from '../../Services/FetchData';
import FieldErrors from '../../Services/FieldErrors';
import { getResourceUrl } from '../../Services/CommanFunctions';
import NotificationUI from '../common/NotificationUI';
import { getMaskedText, removeField } from '../../helpers/utils';

function SchoolParent() {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [school, setSchool] = useState({});
  const [parent, setParent] = useState([]);
  const [index, setIndex] = useState(0)
  const [selectedParent, selectParent] = useState()
  const [error, setError] = useState();
  const [validated, setValidated] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'))
  const history = useHistory();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
  })
  const [fieldError, setFieldError] = useState()
  const [filter, setFilter] = useState('')
  // const { ...sf_data } ={} 
  // if(selectedParent?.gaurdian_details[0]){
  //     sf_data=selectedParent?.gaurdian_details[0]
  // };

  const getParentOfSchool = () => {
    FecthData.getData('guardian/get_school_wise_gaurdian/' + id + '?page=' + 0 + '&filter=' + filter).then(
      (data) => {
        setParent(data?.data)
        if (index)
          delete data.data[index].gaurdian_details[0]._id
          selectParent(data?.data[index])
      },
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
  }
  const submitForm = (e) => {
    e.preventDefault()
    FecthData.postJsonData(formData, 'guardian/add_parent_by_admin').then(
      (data) => {
        if (data?.data) {
          NotificationManager.success(data.message, 'success!', 2000)

          handleClose()
        } else {
          setFieldError(data.errors.details)

        }
      }
    )
  }
  useEffect(() => {
    FecthData.getData('school/get_school_by_id/' + id).then(
      (data) => setSchool(data),
      (error) => {
        setError(error.toString())
        NotificationManager.error(error.toString(), 'error!', 2000);
      }
    )
  }, [id]);
  useEffect(() => {
    getParentOfSchool()
  }, [filter]);
  const logout = () => {
    localStorage.clear()
    history.push('/admin')
  }
  const changeUserStatus = (userId, changedStatus) => {
    Admin.changeUserStatus({ user_id: userId, status: changedStatus }).then(res => {
      getParentOfSchool()
    })
  }
  const removeField1=(data)=>{
    if (data && data?.gaurdian_details[0]) {
      const data1=data?.gaurdian_details[0]
      delete data1["_id"];
      return data1
    }
    return data
  
  }
  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <AdminSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
            </Row>
            <div className="card">
              <Row>
                <Col md={6} className="mt-2">
                  <div className="d-flex align-items-center">
                    <div className="school-logo-small">
                      <img src={school?.data?.logo ? getResourceUrl(school?.data?.logo) : "default_logos/school-logo.png"} alt=" " />
                    </div>
                    <h4 className="text-muted mb-0">{school?.data?.school_name}</h4>
                  </div>
                </Col>
                <Col md={6} className="mt-4">
                  <div className="filters">
                    <ul>
                      <li>
                        {/* <a href="javascript:void(0)">Sort <img src="images/sort-icon.png" alt="" width="17" />
                        </a> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Sort</option>
                          <option value={'ASC'}>Alphabetic A-Z</option>
                          <option value={'DESC'}>Alphabetic Z-A</option>
                        </Form.Select>
                      </li>
                      <li>
                        {/* <a href="javascript:void(0)">Filter <img src="images/filter-icon.png" alt="" width="13" />
                        </a> */}
                        <Form.Select style={{ cursor: 'pointer' }} onChange={e => setFilter(e.target.value)}>
                          <option selected disabled>Filter</option>
                          <option value={'Last Modified'}>Last Modified</option>
                          <option value={'Oldest'}>Oldest</option>
                          <option value={'Newest'}>Newest</option>
                        </Form.Select>
                      </li>
                      {/* <li onClick={handleShow}>
                        <a href="javascript:void(0)">Add <img src="images/add-icon.png" alt="" width="15" />
                        </a>
                      </li> */}
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="video-category">
                    <ul>
                      <li>
                        <Link to={'/admin/school/students/' + id}>Students</Link>
                      </li>
                      <li >
                        <Link to={'/admin/school/tutor/' + id}>Tutors</Link>
                      </li>
                      <li>
                        <Link to={'/admin/school/facilitator/' + id}>Facilitators</Link>
                      </li>
                      <li className="active">
                        <Link to={'/admin/school/parent/' + id}>Parents/Guardians</Link>
                      </li>
                      <li>
                        <Link
                          to={'/admin/school/admin/' + id}
                        >School Admin</Link>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="table-responsive">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">ID</th>
                          {/* <th scope="col">Role</th> */}
                          {/* <th scope="col">Gender</th> */}
                          <th scope="col">Email</th>
                          {/* <th scope="col">Edit</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {parent?.map((content, index) => (
                          <tr onClick={() => {
                            setIndex(index)
                            selectParent(content)
                          }}>
                            <td>
                            {content?.gaurdian_details[0] && content?.gaurdian_details[0]?.display_logo == 'yes' && content?.gaurdian_details[0]?.propic? <img src={getResourceUrl(content?.gaurdian_details[0]?.propic)} alt="" width="44" className="rounded me-2" />
                              :<img src={'images/no_img.png'} alt="" width="44" className="rounded me-2" />}
                              {getMaskedText(content?.name,'name')}
                            </td>
                            <td>{content?._id}</td>
                            {/* <td>Parent</td> */}
                            {/* <td>Female</td> */}
                            <td>{getMaskedText(content?.email,'email')}</td>
                            {/* <td><a className="btn btn-primary btn-sm" onClick={() => {
                              alert("work in progress")
                              //  history.push({ pathname: "/admin/user/edit", state: { ...content?.facilitators_details[0], facilitator_id: content?._id, id: content?.facilitator_id, user_role: 'facilitator', student_id: content?.student_id, _id: content?._id } })
                            }
                            }>Edit</a></td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>

          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  {user?.propic && <img src={getResourceUrl(user?.propic)} width={'100%'} height={'100%'} alt="" />}{!user?.propic && <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />}
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} </h5>
                  <p>Status: {user?.status} | {user?.role}</p>
                </div>
                <Dropdown >
                  <Dropdown.Toggle drop='none' variant="">

                  </Dropdown.Toggle >
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={logout}
                    // href="#/action"
                    >Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {selectedParent ? <div className="card">
              <div className="school-side-details">
                <div className="school-logo-circle">
                  <img src={selectedParent?.gaurdian_details[0]?.propic?getResourceUrl(selectedParent?.gaurdian_details[0]?.propic):"images/no_profile_img.png"} alt="" />
                </div>
                <div className="school-more-option">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src="images/more-icon.png" alt="" width="20" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item >
                        <Link className={'dropdown-item'} to={{ pathname: "/admin/user/edit", 
                        state: {
                          ...selectedParent,
                          user_role:'guardian',
                          ...removeField(selectedParent?.gaurdian_details[0],['_id','user_role'])
                        }}}
                          >Edit Account</Link>
                        </Dropdown.Item>
                      {/* <Dropdown.Divider />
                      <Dropdown.Item onClick={() => alert('work in progress')}>
                        <Link className={'dropdown-item'}> Edit Profile </Link>
                        <Link className={'dropdown-item'} to={{ pathname: "/admin/user/edit", state: { ...selectedParent?.gaurdian_details[0], user_role: 'parent', student_id: selectedParent?.student_id, _id: selectedParent?._id } }} > Edit Profile</Link>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#/action">Delete Profile</Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="school-name">
                  <h5>{getMaskedText( selectedParent?.name,'name')}</h5>
                  <h6>ID: {selectedParent?._id}</h6>
                </div>
                <div className="teacher-contact-detail">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <img src="images/teacher-info1.png" alt="" width="54" />
                      </a>
                    </li>
                    <li className='phone-li'>
                      <a href={`tel:${selectedParent?.phone}`} itemRef='ghgf'>
                        <img src="images/teacher-info2.png" alt="" width="54" />
                      </a>
                      {selectedParent?.gaurdian_details[0]?.phone ? <span className='show-phone'>{getMaskedText(selectedParent?.gaurdian_details[0]?.phone,'phone')}</span> : <span className='show-phone'>Phone Not Available</span>}
                    </li>
                    <li>
                      <a href={`mailto:${selectedParent?.email}`}>
                        <img src="images/teacher-info3.png" alt="" width="54" />
                      </a>
                    </li>
                  </ul>
                  <a href="javascript:void(0)" className={`btn btn-${selectedParent.gaurdian_details[0].status == 'Active' ? 'primary' : 'secondary'} solid btn-sm no-shadow d-block`}>Status: {selectedParent.gaurdian_details[0].status}</a>
                </div>
                <div className="about-teacher">
                  {/* <h4>About:</h4>
                  <p>
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                  <h4>Experience:</h4>
                  <p>
                    Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  </p> */}
                  {/* <h4>Gender:</h4>
                  <p>Female</p>
                  <h4>Date of birth</h4>
                  <p>2 Jan 2003</p> */}
                  <h4>Email:</h4>
                  <p>{getMaskedText(selectedParent?.email,'email')}</p>
                  <h4>Students:</h4>
                  <div className="tutor-subject mt-2">
                    <ul>
                      {selectedParent?.students?.map(student => <li>{student?.name}</li>)}
                      <li>
                        Dvide
                      </li>
                      <li>
                        Richerd
                      </li>
                      <li>
                        Piter
                      </li>
                    </ul>
                  </div>
                </div>
                {selectedParent.gaurdian_details[0].status == 'Inactive' ? <Row>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill no-shadow" variant="primary" onClick={() => changeUserStatus(selectedParent?.user_id, 'Active')}>Admit</Button>
                  </Col>
                  <Col md="6" className="text-center mt-4">
                    <Button className="px-4 btn-pill" variant="secondary" onClick={() => changeUserStatus(selectedParent?.user_id, 'Inactive')}>Decline</Button>
                  </Col>
                </Row> : null}
              </div>
            </div> : null}
          </div>
        </div>
      </section >

      <Modal show={show} onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add parent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitForm}>
            <div className="modal-form">
              <Row>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="parent First Name" value={formData.first_name} onChange={(e) => setFormData({ ...formData, first_name: e.target.value })} />
                  <FieldErrors errors={fieldError} field="first_name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="parent Last Name" value={formData.last_name} onChange={(e) => setFormData({ ...formData, last_name: e.target.value })} />
                  <FieldErrors errors={fieldError} field="last_name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="parent Contact Number" value={formData.phone} onChange={(e) => setFormData({ ...formData, phone: e.target.value })} />
                  <FieldErrors errors={fieldError} field="phone" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" placeholder="parent Email" value={formData.email} onChange={(e) => setFormData({ ...formData, email: e.target.value })} />
                  <FieldErrors errors={fieldError} field='email' />
                </Form.Group>
                {/* <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="Mather High School" placeholder="School Name" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="0221457" placeholder="School Code" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="text" value="5835 N LincoIn Ave" placeholder="Address" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="Chicago" placeholder="City" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="Illinois" placeholder="State" />
                </Form.Group>
                <Form.Group className="form-group mb-3" as={Col} md="6">
                  <Form.Control type="email" value="60659" placeholder="Zip Code" />
                </Form.Group> */}
                <Col md="12" className="text-center mt-2">
                  <Button className="px-4 btn-pill" variant="primary" type='submit'>Send Request</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

    </>
  )
}

export default SchoolParent
