import React, { useEffect, useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  Col,
  Row,
  Button,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import FacilitatorSideMenu from "./FacilitatorSideMenu";
// import DatePicker from "react-horizontal-datepicker";
import Logout from '../logout';
import MyScheduleUI from '../common/MyScheduleUI';
import { FecthData } from '../../Services/FetchData';
import NotificationUI from '../common/NotificationUI';
import { cancelSession } from '../../Services/CommanFunctions';

function FacilitatorSchedule() {
  const user = JSON.parse(localStorage.getItem('user'))
  const [sessions, setSessions] = useState([])

  const selectedDay = val => {
    console.log(val);
  };
  const getAllSessions = () => {
    FecthData.getData('facilitator/get_facilitators_student_session/' + user?.email).then(
      (data) => setSessions(data?.data)
    )
  }
  const cancelTutorSession=(session_id,cancelled_by)=>{
    cancelSession(session_id,cancelled_by).then(()=>{
      getAllSessions()
    })
  }
  useEffect(() => {
    getAllSessions()
  }, [])

  return (
    <>
      <section style={{ height: '100%', width: 'auto' }}>
        <div className="dashboard-wrap">
          <FacilitatorSideMenu />
          <div className="middle">
            <Row>
              <Col md={12}>
                <Form.Group className="form-group mb-2">
                  <Form.Control type="text" placeholder="Search" className="search-input" />
                  <Button variant="primary" type="submit" className="search-btn">
                    <img src="images/search-icon.png" alt="" width="22" />
                  </Button>
                </Form.Group>
              </Col>
              <MyScheduleUI sessions={sessions} role='facilitator' cancelTutorSession={cancelTutorSession}/>
              {/* <Col md={12}>
                <div className="tutor-list mt-3">
                  <div className="class-date-time">
                    <ul>
                      <li>
                        10:00 AM-10:30 AM
                      </li>
                      <li>
                        Monday, Aug 06
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item active">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar2.png" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>ID: 567876</h6>
                        <h5>Algebra Chapter 1: Expressions & Equations</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Link to="/facilitator-join-class" className="btn btn-success px-5 mt-3">Start Class Now</Link>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/algebra.png" alt="" />
                    </div>
                  </div>
                  <div className="class-date-time">
                    <ul>
                      <li>
                        12:00 PM-1:00PM
                      </li>
                      <li>
                        Monday, Aug 06
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar3.png" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>Sandra D.</h6>
                        <h5>Chemistry Chapter 3: Symbols Of Elements</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Button variant="light" className="px-5 mt-3"> Time Left 2:55:00</Button>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/geometry.png" alt="" />
                    </div>
                  </div>
                  <div className="class-date-time">
                    <ul>
                      <li>
                        05:00 PM-06:00 PM
                      </li>
                      <li>
                        Monday, Aug 06
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar1.png" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>David T.</h6>
                        <h5>Geography Chapter 4: Cultural Geography</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Button variant="light" className="px-5 mt-3"> Time Left 5:30:00</Button>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/calcuus.png" alt="" />
                    </div>
                  </div>
                  <div className="class-date-time">
                    <ul>
                      <li>
                        10:00 AM-10:30 AM
                      </li>
                      <li>
                        Tuesday, Aug 07
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar2.png" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>Sandra D.</h6>
                        <h5>Chemistry Chapter 3: Symbols Of Elements</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Button variant="light" className="px-5 mt-3"> Time Left 14:55:00</Button>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/geometry.png" alt="" />
                    </div>
                  </div>
                  <div className="class-date-time">
                    <ul>
                      <li>
                        12:00 PM-1:00PM
                      </li>
                      <li>
                        Tuesday, Aug 07
                      </li>
                    </ul>
                  </div>
                  <div className="tutor-item">
                    <div className="tutor-wrap">
                      <div className="tutor-avatar">
                        <img src="images/avatar3.png" alt="" />
                      </div>
                      <div className="tutor-subject">
                        <h6>David T.</h6>
                        <h5>Geography Chapter 4: Cultural Geography</h5>
                        <p>Facilitator: Jane Doe | Session: Virtual</p>
                        <Button variant="light" className="px-5 mt-3"> Time Left 24:30:00</Button>
                      </div>
                    </div>
                    <div className="tutor-icon">
                      <img src="images/calcuus.png" alt="" />
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
          <div className="right">
            <div className="user-short-text">
            <NotificationUI/>
              {/* <div className="notification-bell">
                <img src="images/bell-icon.png" alt="" width="32" />
                <div className="noti-dot"></div>
              </div> */}
              <div className="user-short-profile">
                <div className="user-avatar">
                  <img src="images/no_profile_img.png" width={'100%'} height={'100%'} alt="" />
                </div>
                <div className="user-short-name">
                  <h5>{user?.name} | ID: 567872</h5>
                  <p>Status: {user?.status}</p>
                </div>
                <Logout url={'/facilitator'} />
                {/* <div className="drop-down">
                  <img src="images/drop-icon.png" alt="" width="10" />
                </div> */}
              </div>
            </div>
            {/* <div className="upcom-class">
              <h5 className="text-muted">Calendar</h5>
              <DatePicker getSelectedDay={selectedDay}
                endDate={100}
                selectDate={new Date("2022-02-08")}
                labelFormat={"MMMM"}
                color={"#287AEB"}
              />
              <div className="upcoming-class-schedule">
                <ul>
                  <li className="eight-am">
                    <div className="left">
                      <p>8 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Chemistry with Jane D.</h5>
                        <p>8:00 am-9:00 am</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="nine-am">
                    <div className="left">
                      <p>9 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Algebra with Sarah D.</h5>
                        <p>9:00 am-9:30 am</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="ten-am mt-4">
                    <div className="left">
                      <p>10 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Geography with John M.</h5>
                        <p>8:00 am-9:00 am</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="eleven-am no-class">
                    <div className="left">
                      <p>11 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Physics with David G.</h5>
                        <p>12:00 pm-1:00 pm</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="twelve-pm">
                    <div className="left">
                      <p>12 AM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Physics with David G.</h5>
                        <p>12:00 pm-1:00 pm</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="one-pm">
                    <div className="left">
                      <p>1 PM</p>
                    </div>
                    <div className="right">
                      <div className="classname">
                        <h5>Geometry with Jane D.</h5>
                        <p>1:00 pm-1:30 pm</p>
                      </div>
                      <div className="three-dot">
                        <img src="images/three-dot.png" alt="" width="20" />
                      </div>
                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>2 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>3 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                  <li className="no-class">
                    <div className="left">
                      <p>4 PM</p>
                    </div>
                    <div className="right">

                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </section>

    </>
  )
}

export default FacilitatorSchedule
